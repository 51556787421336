
/* Desktop's & Laptop's */

@media only screen
  and (min-width: 1100px) {

  html {
    height: 100%;

  }

  body {
    height: 100%;
    margin: 0;

    background-color: white;
  }

  /* <----------> Information Section <----------> */

  .currentSection {
    height: 100%;
    width:100%;

    padding-top: 180px;
    padding-bottom: 40px;
    text-align: center;
  }

  .contents {
    height: 1000px;
    width: 70%;
    left: 50%;
    position:relative;
    display: flex;

    transform: translate(-43%, 0);

    padding: 5px;

    border: 12px double black;
    background: white;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 5px;
  }

  /* <----------> Navigation Bar <----------> */

  #navBar {
    height: 75px;
    width: 100%;
    z-index: 1;

    top: 0;
    position: fixed;

    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;

    align-items: center;

    background-color: white;
  }

  #navBarSpacer {
    height: 6px;
    width: 90%;

    right: 0;

    margin-top: 10px;

    position: fixed;

    border-radius: 5px 0px 0px 0px;
    border-top: thin solid black;
    border-left: thin solid black;
    background-color: white;
  }

  #logoStatementContainer {
    height: 100%;
    width: 75px;

    margin-left: 2%;

    text-align: center;
  }

  #pageLogo {
    cursor: default;
  }

  #pageStatement {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size:0.15cm;
  }

  #buttonContainer {
    height: 100%;
    width: 100%;

    display: flex;

    align-items: center;
  }

  .line {
    height: 25px;
    width: 1px;

    background-color: black;
  }

  .button {
    height: 100%;
    width: 18.75%; 

    justify-content: center;
    align-items: center;
    vertical-align: middle;

    display: flex;
  }
  
  .btn {
    line-height: 50px;
    width: 75%;

    text-align: center;
    
    cursor: pointer;
  }

  .pageButton {
    position: relative;

    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #646464;
    transition: all 0.5s;
  }

  .pageButton:hover {
    color: black;
  }

  .pageButton::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 1;

    content: '';

    background-color: rgba(255,255,255,0.1);
    transition: all 0.3s;
  }

  .pageButton:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
  }

  .pageButton::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    content: '';
    position: absolute;

    opacity: 0;
    border: 1px solid black;
    transition: all 0.3s;
    transform: scale(1.2,1.2);
  }

  .pageButton:hover::after {
    opacity: 1;
    transform: scale(1,1);
  }
}

/* Tablet's */

/* Smartphone's */

